import React from 'react'
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Navbar from './components/NavBar/Navbar'
import Home from './components/Home'
import About from './components/About'
import TTT from './components/Tripteez'
import StripeTTT from './components/StripeTTT'
import SeeFour from './components/SeeFour'
export default function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route exact={true} path='/' element={<Home />} />
          <Route path='/walter' element={<TTT />} />
          <Route path='/walter-stripe' element={<StripeTTT />} />
          <Route path='/horace' element={<SeeFour />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </Router>
  )
}
