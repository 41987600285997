import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Game from './Tripteez/Game.js'
import CheckoutForm from './Stripe/Stripe.js';
import './StripeTTT.css'

const stripePromise = loadStripe("pk_test_51K1b60KQTw1H2n2lljhI5vf7moqo2TA3s3j39De3nnvBIS8UlsyVYl4EHJM4WB0kdmOQ37i8tpq2Sut1wxQFctJo00UlBnnGNa");

function useQuery() {
  const { search } = useLocation();
  const values = queryString.parse(search)

  return values
}

const calculate_dollahz = (amount) => {
  const divided_amount = amount / 100;
  return divided_amount.toFixed(2);
}

export default function StripeTTT () {
  let query = useQuery()
  const [ token, setToken ] = useState(JSON.parse(localStorage.getItem('TTToken')))
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ price, setPrice ] = useState(null)
  const [ clientSecret, setClientSecret ] = useState("");
  const [ payButtonDisabled, setPayButtonDisabled ] = useState(false)
  const [ paymentId, setPaymentId ] = useState('payment_intent' in query ? query.payment_intent : null)

  const api_url = 'https://api.kubernaughty.com/ttt'

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const get_secret = () => {
    setPayButtonDisabled(true)
    fetch(`${api_url}/purchase/create-payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }

  const handlePayment = () => {
    get_secret()
  }

  useEffect(() => {
    if (paymentId !== null && token === null) {
      fetch(`${api_url}/purchase/get-token/${paymentId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then((res) => res.json())
      .then((data) => {
        if ('token' in data) {
          localStorage.setItem('TTToken', JSON.stringify(data.token))
          setToken(data.token)
        }
      })
    }
  }, [paymentId]);

  useEffect(() => {
    if (price !== null) setIsLoaded(true)
  }, [price]);

  useEffect(() => {
    if (token === null && paymentId === null) {
      fetch(`${api_url}/purchase/current-price`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })
      .then((res) => res.json())
      .then((data) => {if ('current_price' in data) setPrice(data.current_price)})};
  }, [])
  
    return (
        <Fragment>
            <div id="heading">
                <h1>Play Walter (stripe)</h1>
            </div>
            <section id="main" className="wrapper">
                <div className="inner">
                  <div className="content">
                    {
                      !token && isLoaded &&
                      <div>
                        <div className="button-holster"><a href="https://www.complex.com/pop-culture/2021/01/fans-react-south-park-world-of-warcraft-cosplayer-died-from-covid-19" target="_blank"><img className="walter" src='/images/walter.png' alt='Jarod' /></a></div>
                        <div className="button-holster">Walter ain't free!  If you want to play the master, you've gotta pay!!</div>
                        {isLoaded && <div className="button-holster"><input type="button" name="one" className="button" value={"PPAY $" + calculate_dollahz(price) + " NAUW"} disabled={payButtonDisabled} onClick={() => handlePayment()} /></div>}
                      </div>
                    }

                    {clientSecret && (
                      <div className="button-holster">
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    )}

                    {token && <Game token={token} />}
                  </div>
                </div>
            </section>
        </Fragment>
    )
}

