import { useState, useEffect, useCallback, useRef } from 'react';
import { useForm } from "react-hook-form"
import './Board.css'

export default function Game (props) {
  const [gameInitialized, setGameInitialized] = useState(false)
  const [gameUUID, setGameUUID] = useState(JSON.parse(localStorage.getItem('gameUUID')))
  const [playerMap, setPlayerMap] = useState(Array(3).fill(null))
  // const [humanPlayer, setHumanPlayer] = useState(null)
  const [isHoraceP1, setIsHoraceP1] = useState(false)
  const [gameWinner, setGameWinner] = useState(null)
  const [winningMove, setWinningMove] = useState([])
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [gameStatus, setGameStatus] = useState({'state': [
    Array(7).fill(0), Array(7).fill(0), Array(7).fill(0), Array(7).fill(0), Array(7).fill(0), Array(7).fill(0)
  ]})
  const [availableMoves, setAvailableMoves] = useState([])
  const { register } = useForm({defaultValues: {player: "b"}})
  const playerRef = useRef()

  const api_url = 'https://api.kubernaughty.com/c4'

  const pullGameStatus = (uuid) => {
    const promise = fetch(`${api_url}/game/getgamestatus/${uuid}`)
      .then(res => res.json())
      .then(res => {if (res.result === 'success') setGameStatus(res.status)})
    return promise
  }

  const pullAvailableMoves = (uuid) => {
    const promise = fetch(`${api_url}/game/getavailablemoves/${uuid}`)
      .then(res => res.json())
      .then(res => {if (res.result === 'success') setAvailableMoves(res.moves)})
    return promise
  }

  const pullWinningMove = (uuid) => {
    const promise = fetch(`${api_url}/game/getwinningmove/${uuid}`)
      .then(res => res.json())
      .then(res => {if (res.result === 'success') setWinningMove(res.winning_move)})
    return promise
  }

  const horaceMove = useCallback(() => {
    setButtonsDisabled(true)
    fetch(`${api_url}/horace/makemove/${gameUUID}`, {
      method: 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }})
        .then(res => res.json())
        .then(res => {
          setGameStatus(res.status)
          setAvailableMoves(res.available_moves)
        })
        .then(res => setButtonsDisabled(false))
  }, [gameUUID])

  const makeMove = useCallback((x, y) => {
    const move = [x, y]
    if (availableMoves.some(attempt => attempt.every((element, index) => Object.is(move[index], element)))) {
      setButtonsDisabled(true)
      fetch(`${api_url}/game/move/${gameUUID}`, {
      method: 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({"move": move})})
        .then(res => res.json())
        .then(res => {
          setGameStatus(res.status)
          setAvailableMoves(res.available_moves)
          return res
        })
        .then(res => {
          setButtonsDisabled(false)
          return res
        })
        .then(res => {if (res.available_moves.length !== 0) horaceMove()})
    }
  }, [availableMoves, gameUUID, horaceMove])

  const initializeGame = useCallback((uuid) => {
    pullGameStatus(uuid)
    .then(pullAvailableMoves(uuid))
    .then(() => setGameInitialized(true))
    .then(() => {if (isHoraceP1) horaceMove()})
  }, [isHoraceP1, horaceMove])

  const player_initializer = player_num => {
    const player_letter = playerRef.current.player.value
    if (player_num === 2) {
      setIsHoraceP1(true)
      var ai_player = 1
    } else var ai_player = 2
    
    fetch(`${api_url}/game/initializegame/${player_letter}?ai_player=${ai_player}&token=${props.token}`, {
      method: 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }})
      .then(res => res.json())
      .then(res => {if (res.result === 'success') {
        setGameUUID(res.uuid)
        // setHumanPlayer(player_num)
        localStorage.setItem('gameUUID', JSON.stringify(res.uuid))
      }})
  }

  const resetGame = () => {
    localStorage.removeItem('gameUUID')
    setGameUUID(null)
    setGameInitialized(false)
    setGameWinner(null)
    setIsHoraceP1(false)
    setGameStatus({})
    setAvailableMoves([])
    setWinningMove([])
  }

  useEffect(() => {
    if (gameUUID !== null) {
      initializeGame(gameUUID)
    }
  }, [gameUUID, initializeGame])

  useEffect(() => {
      setGameWinner(gameStatus.winner)
  }, [gameStatus.winner])

  useEffect(() => {
    if (gameWinner === "1" || gameWinner === "2") {
      pullWinningMove(gameUUID)
    }
}, [gameUUID, gameWinner])

  useEffect(() => {
    if (gameStatus.first_player_color !== null) {
      if (gameStatus.first_player_color === "b") {
        setPlayerMap(["#ffffff", '#303030', '#ba1b1b'])
      } else if (gameStatus.first_player_color === "r") {
        setPlayerMap(["#ffffff", '#ba1b1b', '#303030'])
      }
    }
  }, [gameStatus.first_player_color])

  return (
    <div>
      {!gameInitialized &&
      <form ref={playerRef}>
          <div className="button-holster">
            <input {...register("player")} type="radio" value="b" id="Black" />
            <label htmlFor="Black">Black</label>
            <input {...register("player")} type="radio" value="r" id="Red" />
            <label htmlFor="Red">Red</label>
          </div>
          <div className="button-holster">
            <input type="button" name="one" className="button" value="Start as Player 1" onClick={() => player_initializer(1)} />
            <input type="button" name="two" className="button" value="Start as Player 2" onClick={() => player_initializer(2)} />
          </div>
      </form>}
      {gameInitialized && 
      <Board 
        boardStatus={gameStatus.state}
        handleClick={makeMove}
        playerMap={playerMap}
        buttonsDisabled={buttonsDisabled}
        winningMove={winningMove}
      />}
      <div className="button-holster">
      <input type="button" name="one" className="button" value="Reset" onClick={() => resetGame()} />
      </div>
    </div>
  )
}

function Board(props) {
  const renderSquare = (x, y) => {
    const square_index = [x, y]
    return (
      <Square
        color={props.playerMap[props.boardStatus[x][y]]}
        handleClick={() => props.handleClick(x, y)}
        buttonsDisabled={props.buttonsDisabled}
        winningSquare = {props.winningMove.some(attempt => attempt.every((element, index) => Object.is(square_index[index], element))) ? true : false}
      />
    )
  }

  return (
    <div className='game'>
      <div className="board-row">
        {renderSquare(0, 0)}
        {renderSquare(0, 1)}
        {renderSquare(0, 2)}
        {renderSquare(0, 3)}
        {renderSquare(0, 4)}
        {renderSquare(0, 5)}
        {renderSquare(0, 6)}
      </ div>
      <div className="board-row">
        {renderSquare(1, 0)}
        {renderSquare(1, 1)}
        {renderSquare(1, 2)}
        {renderSquare(1, 3)}
        {renderSquare(1, 4)}
        {renderSquare(1, 5)}
        {renderSquare(1, 6)}
      </ div>
      <div className="board-row">
        {renderSquare(2, 0)}
        {renderSquare(2, 1)}
        {renderSquare(2, 2)}
        {renderSquare(2, 3)}
        {renderSquare(2, 4)}
        {renderSquare(2, 5)}
        {renderSquare(2, 6)}
      </ div>
      <div className="board-row">
        {renderSquare(3, 0)}
        {renderSquare(3, 1)}
        {renderSquare(3, 2)}
        {renderSquare(3, 3)}
        {renderSquare(3, 4)}
        {renderSquare(3, 5)}
        {renderSquare(3, 6)}
      </ div>
      <div className="board-row">
        {renderSquare(4, 0)}
        {renderSquare(4, 1)}
        {renderSquare(4, 2)}
        {renderSquare(4, 3)}
        {renderSquare(4, 4)}
        {renderSquare(4, 5)}
        {renderSquare(4, 6)}
      </ div>
      <div className="board-row">
        {renderSquare(5, 0)}
        {renderSquare(5, 1)}
        {renderSquare(5, 2)}
        {renderSquare(5, 3)}
        {renderSquare(5, 4)}
        {renderSquare(5, 5)}
        {renderSquare(5, 6)}
      </ div>
    </div>
  )
}

function Square(props) {
  return (
    <button className={"square" + (props.winningSquare ? ' game-won' : '')} onClick={props.handleClick} disabled={props.buttonsDisabled} style={{background: props.color}} >
      {/* {props.value} */}
    </button>
  );
}