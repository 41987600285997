import React, { Component, Fragment } from 'react'

export default class About extends Component {

    componentDidMount() {
        document.title = 'About';
    }

    render () {
        return (
            <Fragment>
                <div id="heading">
                    <h1>About</h1>
                </div>
                <section id="main" className="wrapper">
                    <div className="inner">
                        <div className="content">
                            <h3><b>I probably don't need a landing page and an About page, but enjoy this placeholder in the meantime.</b></h3>
                            <p>I'm probably gonna delete this eventually.</p>
                            <p>In the meantime, if you have questions, <a href='https://i.kym-cdn.com/photos/images/original/001/207/210/b22.jpg'>check this help reference.</a>  Or just talk to Rob.  
                                He's probably right behind you.  No rights reserved.  Serve and enjoy.</p>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}
