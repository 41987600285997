import React, { Fragment } from 'react'

import Game from './SeeFour/Game.js'

export default function SeeFour () {
    return (
        <Fragment>
            <div id="heading">
                <h1>Play Horace</h1>
            </div>
            <section id="main" className="wrapper">
                <div className="inner">
                  <div className="content">
                    <Game />
                  </div>
                </div>
            </section>
        </Fragment>
    )
}
