import React, { useState } from 'react'
import Menu from './Menu.js'
import './NavBar.css';

function MenuButton(props){
  return (
    <button id="menuButton" 
        onClick={props.handleClick}>Menu</button>
  );
}

export default function Navbar(){
  const [vis, setVis] = useState(false)

  const toggleMenu = () => {
    setVis(!vis)
  }

  return (
    <div>
      <header id="header">
        <a href="/">
        <div className="img-logo" id="logo">
            <img src={process.env.PUBLIC_URL + '/images/cloudy.png'} alt='Pukey Rainbows' />
        </div>
        </a>
        <MenuButton handleClick={() => toggleMenu()}/>
        {vis && 
            <Menu handleClick={() => toggleMenu()}
                menuVisibility={vis}/>
        }
      </header>
    </div>
  )
}