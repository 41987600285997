import React, { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group';
import './NavBar.css';

export default function Menu(props) {
  const [menuLoaded, setMenuLoaded] = useState(false)
  const menuRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleExitClick);
    setMenuLoaded(true)

    return function cleanup() {
      document.removeEventListener("click", handleExitClick);
    }
  }, [])

  const closeMenu = () => {
    setMenuLoaded(false)
  }

  const handleExitClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuLoaded(false)
    }
  }

  return (
    <CSSTransition in={menuLoaded} timeout={500} classNames="menu" unmountOnExit onExited={props.handleClick}>
      <div className="menu" ref={menuRef}>
          <button className="closeButton" onClick={closeMenu}></button>
          <ul className="links">
            
            <li><a href="/">Apps Home</a></li>
            <li><a href="/walter">Tic-Tac-Toe</a></li>
            <li><a href="/horace">Connect FOUR</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="https://www.kubernaughty.com">Back to Kubernaughty</a></li>
          </ul>
      </div>
    </CSSTransition>
  );
}